<template>
    <k-dialog :title="action" :big="devmode && !disableDevmode" @close="close">
        <v-layout :column="$vuetify.breakpoint.smAndDown">
            <v-flex :xs6="devmode && !disableDevmode && !$vuetify.breakpoint.smAndDown">
                <v-alert v-if="error" type="error" :value="true">
                    {{ error.message }}
                </v-alert>
                <v-card-text v-if="!done">
                    <slot></slot>
                </v-card-text>
                <v-card-actions v-if="!done">
                    <v-switch v-if="!disableDevmode" hide-details label="Show API call" v-model="devmode"></v-switch>
                    <v-btn v-if="!processing" color="primary" @click="call()">{{action}}</v-btn>
                    <v-progress-circular v-if="processing" indeterminate color="primary"></v-progress-circular>
                </v-card-actions>

                <v-card-text v-if="done" style="text-align: center; margin: 100px 0;">
                    <v-icon large color="primary">check</v-icon>
                    <slot name="done" v-bind:response="response">
                        <h1>All done!</h1>
                    </slot>
                </v-card-text>
                <v-card-actions v-if="done">
                    <v-btn color="primary" @click="done=false; response=null">Do another</v-btn>
                    <v-btn color="primary" router @click="close">Close</v-btn>
                </v-card-actions>
            </v-flex>
            <v-flex v-if="devmode && !disableDevmode" :xs6="devmode && !$vuetify.breakpoint.smAndDown">
                <v-card-text>
                    <div>
                        <b>Request</b>
                        {{apiCall.method}} {{$global.env.url}}{{apiCall.path}}
                        <div v-if="apiCall.body" v-highlightjs="requestJSON"></div>
                    </div>
                    <div>
                        <b>Response</b>
                        <div v-if="response">
                            {{response.status}} {{response.statusText}}
                            <div v-highlightjs="responseJSON"></div>
                        </div>
                    </div>
                </v-card-text>
            </v-flex>
        </v-layout>
    </k-dialog>
</template>

<script>
import api from '@/services/api';

export default {
  props: [
    'action',
    'apiCall',
    'disableDevmode',
  ],
  data() {
    return {
      devmode: localStorage.getItem('devmode') === 'true',
      response: null,
      done: false,
      error: null,
      processing: false,
    };
  },
  watch: {
    devmode() {
      localStorage.setItem('devmode', this.devmode.toString());
    },
  },
  computed: {
    requestJSON() {
      return JSON.stringify(this.apiCall.body, null, 2);
    },
    responseJSON() {
      return JSON.stringify(this.response.json, null, 2);
    },
  },
  methods: {
    call() {
      this.processing = true;
      this.response = null;
      this.error = null;
      const callData = this.apiCall;
      api.kernel.raw(callData).then((response) => {
        this.response = {
          status: response.status,
          statusText: response.statusText,
          json: '',
        };
        response.json().then((json) => {
          this.processing = false;
          this.response.json = json;
          if (response.status >= 400 && response.status < 600) {
            this.error = json;
          } else {
            this.error = null;
            this.done = true;
            this.$globalEventBus.$emit('object-edited');
          }
        }, () => {
          this.processing = false;
          this.error = {
            message: 'Error connecting to the server. [2]',
          };
        });
      }, () => {
        this.processing = false;
        this.error = {
          message: 'Error connecting to the server. Check your internet connection.',
        };
      });
    },
    close() {
      this.$emit('close');
    },
  },
};

</script>

<style lang="scss">
.hljs {
    white-space: pre;
}
</style>
